<template>
  <div class="home">
	   <Header></Header>
       <div class="update-record-main-box">
			<div class="product01-box">
				<div class="top-p">
					<p class="txt lantinghei">更新记录</p>
				</div>
				<div class="two-nav-box">
					<p class="btn-box Gotham-Book lantingheijian" @click="goAllMsg">最新消息</p>
					<p class="shu Gotham-Book lantingheijian">|</p>
					<p class="btn-box active-btn Gotham-Book lantingheijian" >更新记录</p>
				</div>
				<div class="product01-content-box">
					<div class="left-year-box">
						<div :class="[yearIndex==index?'slide slide-active':'slide']" v-for="(item,index) in yearArray" :key="index" @click="clickYear(index,item.code,item.value)">
							<div class="select-box">
								<img src="../assets/img/select-active.png" class="img1" alt="">
								<img src="../assets/img/select-img.png" class="img2" alt="">
							</div>
							<p class="right-p Gotham-Book lantingheijian">{{item.name}}年</p>
						</div>
					</div>
					<div class="product01-content">
						<div class="left-content" v-if="allMsg&&allMsg.length>0">
							<div class="content-slide" @click="goDetails(item.id)" v-for="(item,index) in allMsg" :key="index">
								<div class="left-text">
									<div class="top-name Gotham-Book lantingheijian">{{item.metadataInfo.CUSTOMER_PORTAL_PRODUCT_NUMBER[0].title}}</div>
									<div class="center-edition Gotham-Book lantingheijian">版本号 {{item.metadataInfo.CUSTOMER_PORTAL_VERSION[0].title}}</div>
									<div class="time-logo">
										<img class="img" src="../assets/img/update-time-logo.png" alt="">
										<p class="time Gotham-Book lantingheijian">{{timestampToTime(item.metadataInfo.CUSTOMER_PORTAL_PUBLISH_TIME)}}</p>
									</div>
								</div>
								<div class="right-img">
									<img :src="item.coverPicture" alt="">
								</div>
							</div>
						</div>
						<div class="left-content" v-else>
							<div class="content-slide2">
								<p class="no-message Gotham-Book lantingheijian">暂无数据</p>
							</div>
						</div>
						<div class="right-see-more">
							<div class="top-tit Gotham-Book lantingheijian">相关咨询</div>
							<div class="see-more-content" v-if="rightMoreArr.records.length>0">
								<a :title="item.metadataInfo.CUSTOMER_PORTAL_PRODUCT_NUMBER[0].title"  v-for="(item,index) in rightMoreArr.records" :key="index"  @click.prevent="goDetails(item.id)" class="more-a Gotham-Book lantingheijian">{{item.metadataInfo.CUSTOMER_PORTAL_PRODUCT_NUMBER[0].title}}</a>
							</div>
                            <div class="see-more-content" v-else>
								<div class="more-a Gotham-Book lantingheijian">暂无数据</div>
							</div>
							<div class="btn-more Gotham-Book lantingheijian" v-if="rightMoreArr.totalPage>1" @click="seeMore">换一换</div>
						</div>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div> 
  </div>
</template>
<script>
//设置html font-size
document.documentElement.style.fontSize =
  document.documentElement.clientWidth / 38.4 + "px";
window.onresize = () => {
  document.documentElement.style.fontSize =
    document.documentElement.clientWidth / 38.4 + "px";
};
/* 
var blockIdArr={
    335 最新消息Banner
    327 最新消息>内容详情
    362 更新信息>机型图
    334 更新记录>内容详情
    333 内测机型Banner
    366 内侧机型>机型列表
    363 二维码-微信
    364 二维码-微博
    indexBanner:335,
    newMsg:327,
    newsjixing:362,
    update:334,
    neicebanner:333,
    neicejixing:366,
    vxcode:363,
    wbcode:364
}
*/
//接口地址引入
import interfaces from "../utils/baseUrl";
import axios from 'axios'
import Header from "../components/Header"
import Footer from "../components/Footer"
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
       allMsg:[],
       pageNum:1,
       pageSize:8,
       searchText:"",
       rightMoreArr:{
		   records:[]
	   },
       morePageNum:1,
       morePageSize:5,
       thisValue:'',
       yearArray:[],
       yearIndex:0
    }
  },
  beforeMount: function () {
    //获取链接参数
    var query = this.$route.query;
    //设置整体展厅参数
    // // this.cacheKey = query.cacheKey;
    // this.getContent({"id": query.searchText})
    // //console.log(query.value);
    this.thisValue=query.value;
	this.getBaseInfo(interfaces.blockIdArr.update)
	 //获取右侧
    this.searchMoreFn({
        "blockId": interfaces.blockIdArr.newsjixing,
        "pageNum":this.morePageNum,
        "pageSize":this.morePageSize,
        "searchText":"",
        "filterConditionList":[]
    })
  },
  //初始化执行
  created: function () {
    let $this = this;
    // $this.getBaseInfo(335)
  },
  //事件
  methods: {
	   seeMore(){
		var $this = this;
		$this.morePageNum=$this.morePageNum+1
		this.searchMoreFn({
				"blockId": interfaces.blockIdArr.newsjixing,
				"pageNum":$this.morePageNum,
				"pageSize":$this.morePageSize,
				"searchText":"",
				"filterConditionList":[]
			})
	  },
	  //获取右侧内容
        searchMoreFn(data){
            var $this = this;
            var config = {
                method: "post",
                url: `${interfaces.baseUrl}/portal/open/block/search`,
                headers:interfaces.postHeaders(),
                data,
                };
        
            axios(config)
                .then(function (response) {
					   $this.rightMoreArr=response.data.result;
					   //console.log( $this.rightMoreArr)
                })
                .catch(function (error) {
                    return error
                });
        },
      //点击年份选择
      clickYear(index,code,value){
        var $this = this;
        $this.yearIndex=index;
        $this.searchFn({
                "blockId": 334,
                "pageNum": 1,
                "pageSize": 20,
                "searchText": "",
                "filterConditionList": [
                    {
                        "type":4,
                        "code":"CUSTOMER_PORTAL_PRODUCT_NUMBER",
                        "value":[$this.thisValue]
                    },
                    {
                        "type":3,
                        "code":code,
                        "value":[value]
                    }
                ]

             })
      },
          //获取导航
   getBaseInfo(id){
        var $this = this;
        var config = {
          method: "get",
          url: `${interfaces.baseUrl}/portal/open/block/public/zui/getBaseInfo/${id}`,
          headers:interfaces.headers(),
        };
  
       axios(config)
          .then(function (response) {
            $this.yearArray=response.data.result.navigators;
			console.log($this.yearArray)
            $this.searchFn({
                "blockId": 334,
                "pageNum": 1,
                "pageSize": 20,
                "searchText": "",
                "filterConditionList": [
                    {
                        "type":4,
                        "code":"CUSTOMER_PORTAL_PRODUCT_NUMBER",
                        "value":[$this.thisValue]
                    },
                    {
                        "type":3,
                        "code":$this.yearArray[0].code,
                        "value":[$this.yearArray[0].value]
                    }
                ]

             })
          })
          .catch(function (error) {
            return error
          });
    },

     //搜索内容区块中内容
    searchFn(data){
		console.log(data)
        var $this = this;
        var config = {
            method: "post",
            url: `${interfaces.baseUrl}/portal/open/block/search`,
            headers:interfaces.postHeaders(),
            data,
            };
    
           axios(config)
            .then(function (response) {
                $this.allMsg=response.data.result.records;
                //console.log($this.allMsg)
            })
            .catch(function (error) {
                return error
            });
      },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
            var D = date.getDate() + '';
            return Y+M+D;
        },
        selectFn(){
            var selectMsg={
                "blockId": interfaces.blockIdArr.newMsg,
                "pageNum":this.pageNum,
                "pageSize":this.pageSize,
                "searchText":this.searchText,
                "filterConditionList":[]
            }
            this.searchFn(selectMsg)
        },
        goDetails(id){
          //console.log(id);
          this.$router.push({
            name: 'newsdetails',
            query:{
			  id:id,
			  two:1,
			  value:this.thisValue
            }
          })
		},
		goAllMsg(){
          this.$router.push({
            name: 'newMessage',
          })
        },
  },
};
</script>
<style lang="less" scoped>
.footer-box{
	background: #ffffff !important;
}
.update-record-main-box{
	width: 100%;
	
	overflow: hidden;
	position: relative;
}
.update-record-main-box img{
	display: block;
	width: 100%;
}
.update-record-main-box .product01-box{
	width: 100%;
	position: relative;
	background: #f5f5f6;
	overflow: hidden;
	min-height: calc(100vh - 3rem);
	padding-top: 1.6rem;
	padding-bottom: .9rem;
	box-sizing: border-box;
}
.update-record-main-box .product01-box .top-p{
	width: 1047px;
	margin: 0 auto;
	margin-top:50px;
	color: #0d0c22;
	font-size: 36px;
}
.update-record-main-box .product01-box .two-nav-box{
	width: 1047px;
	margin:20px auto 0 auto;
	padding-bottom:10px;
	box-sizing: border-box;
	overflow: hidden;
	border-bottom: 1px solid #c6c6cc;
	color: #c7c7c7;
	font-size: 14px;
}
.update-record-main-box .product01-box .two-nav-box .btn-box{
	float: left;
	width:70px;
}
.update-record-main-box .product01-box .two-nav-box .active-btn{
	color:#0d0c22;
}
.update-record-main-box .product01-box .two-nav-box .shu{
	float: left;
	padding-right: 10px;
	font-size: 14px;
	margin-top: 4px;
}
.update-record-main-box .product01-box .product01-content-box{
	position: relative;
	width: 1047px;
	margin: 0 auto;
	margin-top: 25px;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box{
	position: absolute;
	top:0;
	left:-110px;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide{
	overflow: hidden;
	margin-top:13px;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide .select-box{
	position: relative;
	width:16px;
	height: 16px;
	float: left;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide .select-box .img1{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	display: none;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide-active .select-box .img1{
	display: block;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide .select-box .img2{
	position: absolute;
	top: 50%;
	left: 50%;
	width:10px;
	transform: translate(-50%,-50%);
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide-active .select-box .img2{
	display: none;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide .right-p{
	float: left;
	margin-left:5px;
	line-height: 16px;
	color:#c6c6cc;
	font-size:15px;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide-active .right-p{
	color: #0f0f22;
}
.update-record-main-box .product01-box .product01-content-box .product01-content{
	width: 1047px;
	margin: 0 auto;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content{
	width:867px;
	float: left;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide{
	width: 100%;
	height:173px;
	margin-top:10px;
	background: #ffffff;
	border-radius:10px;
	border: 1px solid #dedede;
	padding:15px 0 0 20px;
	box-sizing: border-box;
	overflow: hidden;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide2{
	width: 100%;
	height:173px;
	margin-top:10px;
	background: #ffffff;
	border-radius:10px;
	border: 1px solid #dedede;
	padding:15px 0 0 20px;
	box-sizing: border-box;
	overflow: hidden;
	line-height: 90px;
	font-size: 20px;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide:nth-of-type(1){
	margin-top: 0;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide .left-text{
	float: left;
	
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide .left-text .top-name{
	padding:0 10px;
	height:30px;
	line-height: 30px;
	text-align: center;
	color: #000000;
	font-size:16px;
	border-radius:30px;
	border: 1px solid #7b7a89;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .center-edition{
	margin-top: 14px;
	color: #5a596c;
	font-size:33px;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .time-logo{
	/* display: flex; */
	/* justify-content: center; */
	margin-top: 19px;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .time-logo .img{
	width:14px;
	float: left;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .time-logo .time{
	margin-left:5px;
	float: left;
	line-height:16px;
	font-size: 16px;
	color:#5a596c;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide .right-img{
	float: right;
	margin-right:19px;
	margin-top:6px;
	width:190px;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more{
    float: left;
	width:166px;
	margin-left:14px;
	background: #ffffff;
	border-radius: 5px;
	border: 1px solid #dedede;
	overflow: hidden;
	padding:0 7.5px;
	box-sizing: border-box;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .top-tit{
    width: 100%;
   height:41.5px;
   line-height: 41.5px;
   text-align: center;
   color: #999999;
   font-size: 18px;
   border-bottom: 1px solid #dedede;

}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .see-more-content{
	margin-top:7.5px;
	padding-bottom: 7.5px;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .see-more-content .more-a{
	display: block;
	width: 100%;
	text-align: center;
	color: #0d0c22;
	font-size: 14px;
    margin-top: 5px;
	cursor: pointer;
	overflow:hidden; /*超出的部分隐藏起来。*/ 
	white-space:nowrap;/*不显示的地方用省略号...代替*/
	text-overflow:ellipsis;/* 支持 IE */
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .see-more-content .more-a:hover{
	 text-decoration:underline;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .btn-more{
	width: 100%;
	text-align: center;
	color:#999999;
	font-size: 16px;
	height:28px;
	line-height:28px;
	border-top: 1px solid #dedede;
}
</style>